import { defineStore } from 'pinia';
import { GrowthBook } from '@growthbook/growthbook';
import {
  FEATURE_FLAG,
  HIGHLIGHTED_DEFAULT_TEXT_COLOR,
  NOTES_CHARS_DEFAULT_LIMIT,
} from '@/utils/constants';
import { isValidHexColor } from '@/utils/stringUtils';

export const useFeatureFlagStore = defineStore({
  id: 'feature-flag',
  state: () => ({
    growthbook: null,
  }),

  actions: {
    async initStore() {
      const config = useRuntimeConfig();

      this.growthbook = new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: config.public.growthBookKey,
        enableDevMode: true,
        subscribeToChanges: true,
        trackingCallback: (experiment, result) => {
          console.log('Viewed Experiment', {
            experimentId: experiment.key,
            variationId: result.key,
          });
        },
      });

      await this.growthbook.loadFeatures();
    },

    setDefaultHighlightColor() {
      const configColor = this.growthbook?.getFeatureValue(
        FEATURE_FLAG.highlightColor
      );
      const color = isValidHexColor(configColor)
        ? configColor
        : HIGHLIGHTED_DEFAULT_TEXT_COLOR;

      document.documentElement.style.setProperty(
        '--reader-highlighted-text',
        color
      );
    },
  },
  getters: {
    isSignUpAvailable: (state) =>
      state.growthbook && state.growthbook.isOn(FEATURE_FLAG.signUp),
    isPurchaseAvailable: (state) =>
      state.growthbook && state.growthbook.isOn(FEATURE_FLAG.purchase),
    highlightColor: (state) => {
      const configColor = state.growthbook?.getFeatureValue(
        FEATURE_FLAG.highlightColor
      );
      return isValidHexColor(configColor)
        ? configColor
        : HIGHLIGHTED_DEFAULT_TEXT_COLOR;
    },
    notesCharsLimit: (state) =>
      (state.growthbook &&
        state.growthbook?.getFeatureValue(FEATURE_FLAG.notesCharsLimit)) ||
      NOTES_CHARS_DEFAULT_LIMIT,
    newChatUi: (state) =>
      state.growthbook && state.growthbook.isOn(FEATURE_FLAG.newChatUi),
    isApiResponseCachingEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.apiResponseCaching),
    closedBetaMessageTitle: (state) =>
      state.growthbook?.getFeatureValue(FEATURE_FLAG.closedBetaMessageTitle) ||
      'Thank you for your interest in our product.',
    closedBetaMessageText: (state) =>
      state.growthbook?.getFeatureValue(FEATURE_FLAG.closedBetaMessageText) ||
      'We are currently in a closed-beta. You can apply to be a part of it by filling out an application. If you think this is a mistake, please confirm the email address you used {{ user.email }} against the one you signed up for the beta with.',
    closedBetaLink: (state) =>
      state.growthbook?.getFeatureValue(FEATURE_FLAG.closedBetaLink) || '',
    isLayoutModeSettingsEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isLayoutModeSettingsEnabled),
    isThreadsEnabled: (state) =>
      state.growthbook && state.growthbook.isOn(FEATURE_FLAG.isThreadsEnabled),
    chatWithRebinderTooltipText: (state) =>
      state.growthbook?.getFeatureValue(
        FEATURE_FLAG.chat_with_rebinder_tooltip_text
      ) || 'Chat with Rebinder',
    isFullScreenModeEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isFullScreenModeEnabled),
  },
});
