import { useUserStore } from '@/store/user';
import {
  HTTP_STATUS,
  DEFAULT_CACHE_TIME_MS,
  MESSAGE_DURATION,
} from '@/utils/constants.js';
import { useFeatureFlagStore } from '@/store/feature-flag';
import { ElMessage } from 'element-plus';

export const apiFetch = async (url, opts = {}) => {
  const config = useRuntimeConfig();
  const userStore = useUserStore();
  const nuxtApp = useNuxtApp();
  const featureFlagStore = useFeatureFlagStore();

  const {
    customOptions = {
      handleErrorMessage: {
        showErrorMessage: false,
        errorMessageText: 'Something went wrong. Please try again later.',
      },
      handleLocalError: false,
      useCache: false,
      key: undefined,
      isStreaming: false,
      handleStreamingResponse: null,
    },
    ...options
  } = opts;

  const cacheKey =
    featureFlagStore.isApiResponseCachingEnabled && customOptions?.useCache
      ? customOptions.key
      : undefined;

  if (userStore.token && userStore.isTokenExpired()) {
    try {
      const token = await nuxtApp.$auth0.getTokenSilently();
      userStore.saveTokenData(token);
    } catch (error) {
      await nuxtApp.$auth0.loginWithRedirect();
      return;
    }
  }

  const handleAuthError = () => {
    if (process.server) return;
    window.location.reload();
  };

  const handleResponseError = ({ response }) => {
    if (customOptions?.handleLocalError) {
      return response;
    }

    if (
      customOptions?.handleErrorMessage?.showErrorMessage &&
      response.status !== HTTP_STATUS.UNAUTHORIZED
    ) {
      ElMessage.error({
        message: customOptions.handleErrorMessage.errorMessageText,
        duration: MESSAGE_DURATION,
      });

      return;
    }

    if (
      response.status === HTTP_STATUS.INTERNAL_SERVER_ERROR ||
      response.status === HTTP_STATUS.NOT_FOUND
    ) {
      showError({
        statusCode: response.status,
        statusMessage: response.statusText,
      });
    }

    if (response.status === HTTP_STATUS.UNAUTHORIZED) {
      handleAuthError();
    }

    return response;
  };

  const fetchOptions = {
    key: cacheKey,
    baseURL: `${config.public.apiBase}api/${config.public.apiVersion}/`,
    server: false,
    ...options,
    onRequest({ options }) {
      options.headers = {
        Accept: 'application/json',
        ...(userStore.token && { Authorization: `Bearer ${userStore.token}` }),
      };
    },
    transform(response) {
      return featureFlagStore.isApiResponseCachingEnabled &&
        customOptions?.useCache
        ? { response, fetchedAt: Date.now() }
        : { response };
    },
    getCachedData(key) {
      if (
        !featureFlagStore.isApiResponseCachingEnabled ||
        !customOptions?.useCache
      )
        return;

      const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
      if (!data) return;

      const expDate = new Date(data.fetchedAt);
      expDate.setTime(expDate.getTime() + DEFAULT_CACHE_TIME_MS);
      const isExpired = expDate.getTime() < Date.now();

      if (isExpired) {
        return;
      }

      return data;
    },
    onResponseError: handleResponseError,
    ...(customOptions.isStreaming && { responseType: 'stream' }),
    onResponse({ response }) {
      if (
        customOptions?.isStreaming &&
        customOptions?.handleStreamingResponse
      ) {
        customOptions.handleStreamingResponse(response);
      }
    },
  };

  return useFetch(url, fetchOptions);
};
