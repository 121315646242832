export const isValidHexColor = (color) => {
  if (!color) {
    return false;
  }
  return /^#[0-9A-F]{6}(?:[0-9A-F]{2})?$/i.test(color);
};

export const trimString = (str, length) => {
  if (!str) {
    return '';
  }
  if (str.length > length) {
    return `${str.substring(0, length)}...`;
  }
  return str;
};

export const getDifferencesFromStart = (str1, str2) => {
  let start = 0;

  // Find common prefix
  while (
    start < str1.length &&
    start < str2.length &&
    str1[start] === str2[start]
  ) {
    start++;
  }

  // Extract differences
  const diff1 = str1.slice(start);
  const diff2 = str2.slice(start);

  return { diff1, diff2 };
};
