/**
 * Function analytic track
 * @param {string} name - name track
 * @param {object} params - data to analytics send
 */
export async function sendAnatylics(name, params) {
  if (typeof umami !== 'undefined') {
    // eslint-disable-next-line no-undef
    umami.track(name, params);
  }
}
