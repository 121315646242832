import { API_METHOD } from '@/utils/constants';
import { apiFetch } from '@/utils/apiFetch';

// Get user's data
let apiGetUserController = null;
export const apiGetUser = async () => {
  apiGetUserController?.abort?.();
  apiGetUserController = new AbortController();

  const { data } = await apiFetch('users/me', {
    method: API_METHOD.GET,
    signal: apiGetUserController.signal,
    customOptions: {
      handleLocalError: true,
    },
  });

  return data.value.response;
};

// Update user's data
export const apiUpdateUser = async (body) => {
  const { data } = await apiFetch('users/me', {
    method: API_METHOD.PATCH,
    body,
  });

  return data.value.response;
};
