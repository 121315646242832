import { defineStore } from 'pinia';
import { jwtDecode } from 'jwt-decode';
import { apiGetUser, apiUpdateUser } from '@/api/users';

export const useUserStore = defineStore({
  id: 'user',
  state: () => {
    return {
      user: null,
      token: null,
      tokenExpirationTime: null,
      isReady: false,
      controller: null,
    };
  },

  actions: {
    async getUser() {
      const data = await apiGetUser();

      this.user = data || null;
      this.isReady = true;

      return data;
    },

    saveTokenData(token) {
      if (!token) return;
      const decodedToken = jwtDecode(token);
      this.token = token;
      this.tokenExpirationTime = decodedToken.exp * 1000;
    },

    cleanupUserData() {
      this.user = null;
      this.token = null;
      this.tokenExpirationTime = null;
    },

    async updateUser(payload) {
      const data = await apiUpdateUser(payload);

      this.user = data || this.user;
    },

    isTokenExpired() {
      if (!this.tokenExpirationTime) return true;
      // Token is considered expired 5 minutes before its actual expiration time
      return new Date(this.tokenExpirationTime - 5 * 60 * 1000) < new Date();
    },

    updateReaderSettings(settings) {
      if (!this.user?.reader_settings) return;

      this.user = {
        ...this.user,
        reader_settings: {
          ...this.user.reader_settings,
          ...settings,
        },
      };

      this.updateUser({
        reader_settings: this.user.reader_settings,
      });
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    readerSettings: (state) => state.user?.reader_settings || {},
    readerSettingsSelectedTheme: (state) => state.user?.reader_settings?.theme,
    readerSettingsSelectedFont: (state) => state.user?.reader_settings?.font,
    readerSettingsSelectedFontSize: (state) =>
      state.user?.reader_settings?.font_size,
    readerSettingsSelectedPageLayout: (state) =>
      state.user?.reader_settings?.page_layout,
    readerSettingsSelectedNavigation: (state) =>
      state.user?.reader_settings?.navigation,
  },
});
